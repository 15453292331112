import React from 'react';
import '../../App.css';
import ContactForm from '../controls/ContactForm';

function Contact() {

  return (
    <>
      <ContactForm />
    </>
  )
}
export default Contact
