import React from 'react';
import '../../App.css';
import Cards from '../controls/Cards';

export default function Services() {
  return (
    <>
      <Cards />
    </>
  )
}