import React from 'react';
import '../../App.css';
import MainSection from '../controls/MainSection';

function Home() {
  return (
    <>
      <MainSection />
    </>
  );
}

export default Home;